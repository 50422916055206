export const msalConfig = {
    authority: 'https://login.microsoftonline.com/01878817-7703-423d-a5ab-4a4806a233ea',
   
  //  redirectUri: document.getElementById('root').baseURI,
    //  clientId: 'a33690d1-91c8-4eed-9915-9ffb881181ba'
    clientId: "aa7c008c-dade-4f20-9cea-331820793aab",
   /* clientId: "6780e2e5-3519-42ee-bd0f-42b09aaeedff",*/
    redirectUri: document.getElementById('root').baseURI,

    /*redirectUri: document.getElementById('root').baseURI + 'signin-oidc'*/
}